import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AwesomeSlider from 'react-awesome-slider';
import ShowGallery from '../Gallery';
import 'react-awesome-slider/dist/styles.css';
import Form from '../Form';

const NaviTab = () => {
  const onAnimationStart = ({
    element,
    currentIndex,
    nextIndex,
    currentScreen,
    nextScreen,
  }) => {
    /*
      ... do Something
    */
  };

  return (
    <Tabs>
      <TabList>
        <Tab>Home</Tab>
        <Tab>BigScreen</Tab>
        <Tab>Contact</Tab>
      </TabList>

      <TabPanel>
        <ShowGallery />
      </TabPanel>
      <TabPanel>
        <AwesomeSlider>
          <div data-src="https://farm1.staticflickr.com/333/18623649619_fdf8e63090_b.jpg" />
          <div data-src="https://farm4.staticflickr.com/3926/15149715760_e97d63cbbe_b.jpg" />
          <div data-src="https://farm5.staticflickr.com/4360/36578848130_f110099358_b.jpg" />
          <div data-src="https://farm4.staticflickr.com/3728/13774497803_0d8e648a2b_b.jpg" />
        </AwesomeSlider>
      </TabPanel>
      <TabPanel>
        <h2>Lennart Takanen</h2>
        +358 40 708 2294
        <br />
        lennart@takanen.eu
      </TabPanel>
    </Tabs>
  );
};

export default NaviTab;
