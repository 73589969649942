import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const WhatsAppWidgetContainer = () => {
  return (
    <WhatsAppWidget
      phoneNo="358407082294"
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={true}
      autoOpenTimer={5000}
      messageBox={false}
      messageBoxTxt="Hei, Haluaisin tiedustella lisää ?"
      iconSize="40"
      iconColor="white"
      iconBgColor="tomato"
      headerIcon="https://www.takanen.eu/lennart_logo.png"
      headerIconColor="pink"
      headerTxtColor="black"
      headerBgColor="tomato"
      headerTitle="Lennart Takanen"
      headerCaption="Vastaa yleensä päivän sisällä"
      bodyBgColor="#bbb"
      chatPersonName="Support"
      chatMessage={<>Hei 👋 <br /><br /> Haluatko tilata palvelun?</>}
      footerBgColor="#999"
      btnBgColor="yellow"
      btnTxtColor="black"
      btnTxt="Aloita chat"
    />
  );
};

export default WhatsAppWidgetContainer;
