import React from 'react';
import { render } from 'react-dom';

import Header from './Components/Header/index.tsx';
import NaviTab from './Components/NaviTab/index.tsx';
import Footer from './Components/Footer/index.tsx';
import WhatsAppWidgetContainer from './Components/WhatsAppWidget/index.tsx';

render(
  <div>
    <div>
      <Header />
    </div>
    <NaviTab />
    <Footer />
    <WhatsAppWidgetContainer />
  </div>,
  document.getElementById('root'),
);
