import * as React from 'react';
import Gallery from 'react-grid-gallery';

class ShowGallery extends React.Component {

 
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        picData: [],
      };
    }
    
    componentDidMount() {
      fetch(
        'https://www.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=db1016c28c842dd218f046b248e9b55f&photoset_id=72157715569139551&format=json&nojsoncallback=1',
      )
        .then((response) => response.json())
      .then((data) => this.setState({
        isLoaded: true,
        data 
      }));
    }
    render(){
    const string = JSON.stringify(this.state);
    const data2 = JSON.parse(string);

    let pictures: Array<Object> = [];

    if (data2.data.photoset){
      data2.data.photoset.photo.forEach(photo => { 
        const {id, farm, server, secret, title} = photo;
        //https://www.flickr.com/services/rest/?method=flickr.photos.getSizes&api_key=db1016c28c842dd218f046b248e9b55f&photo_id=14144445326&format=json&nojsoncallback=1

        const partialPhoto = {
          src: `https://farm${farm}.staticflickr.com/${server}/${id}_${secret}_b.jpg`, 
          thumbnail: `https://farm${farm}.staticflickr.com/${server}/${id}_${secret}_q.png`,
          thumbnailHeight: 150,
          isSelected: false,
          caption: title,
      }
        pictures.push(partialPhoto)
        
      });
    }
    const IMAGES = pictures;

    return (
      <Gallery images={IMAGES} enableImageSelection={false} backdropClosesModal={true} />
    );    
    }
};

export default ShowGallery;
