import * as React from 'react';
import MetaTags from 'react-meta-tags';

const Header = () => {


  return (
    <>
      <MetaTags>
        <title>takanen.eu</title>
        <meta id="meta-description" name="description" content="Takanen.eu." />
        <meta id="og-title" property="og:title" content="taknen.eu" />
        <meta id="og-image" property="og:image" content="lennart_logo.png" />
      </MetaTags>

      <div>
        <img alt="" src="https://www.takanen.eu/lennart_logo.png" width="350" />
      </div>
    </>
  );
};

export default Header;
